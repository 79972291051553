@import "open-color/open-color.scss";
@import "../css/variables.module";

.excalidraw {
  .ToolIcon {
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    user-select: none;
  }

  .ToolIcon--plain {
    background-color: transparent;
    .ToolIcon__icon {
      width: 2rem;
      height: 2rem;
    }
  }

  .ToolIcon_type_radio,
  .ToolIcon_type_checkbox {
    & + .ToolIcon__icon {
      background-color: var(--button-gray-1);

      &:hover {
        background-color: var(--button-gray-2);
      }
      &:active {
        background-color: var(--button-gray-3);
      }
    }
  }

  .ToolIcon__icon {
    width: 2.5rem;
    height: 2.5rem;
    color: var(--icon-fill-color);

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: var(--border-radius-lg);

    & + .ToolIcon__label {
      margin-inline-start: 0;
    }

    svg {
      position: relative;
      height: 1em;
      fill: var(--icon-fill-color);
      color: var(--icon-fill-color);
    }
  }

  .ToolIcon__label {
    display: flex;
    align-items: center;
    color: var(--icon-fill-color);
    font-family: var(--ui-font);
    margin: 0 0.8em;
    text-overflow: ellipsis;

    .Spinner {
      margin-left: 0.6em;
    }
  }

  .ToolIcon_size_small .ToolIcon__icon {
    width: 2rem;
    height: 2rem;
    font-size: 0.8em;
  }

  .excalidraw .ToolIcon_type_button,
  .Modal .ToolIcon_type_button,
  .ToolIcon_type_button {
    padding: 0;
    border: none;
    margin: 0;
    font-size: inherit;

    &:focus-visible {
      box-shadow: 0 0 0 2px var(--focus-highlight-color);
    }

    &.ToolIcon--selected {
      background-color: var(--button-gray-2);

      &:active {
        background-color: var(--button-gray-3);
      }
    }

    &:hover {
      background-color: var(--button-gray-2);
    }

    &:active {
      background-color: var(--button-gray-3);
    }

    &--show {
      visibility: visible;
    }

    &--hide {
      visibility: hidden;
    }
  }

  .ToolIcon_type_radio,
  .ToolIcon_type_checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;

    &:not(.ToolIcon_toggle_opaque):checked + .ToolIcon__icon {
      background-color: var(--button-gray-2);
      &:active {
        background-color: var(--button-gray-3);
      }
    }

    &:focus-visible + .ToolIcon__icon {
      box-shadow: 0 0 0 2px var(--focus-highlight-color);
    }

    &:active + .ToolIcon__icon {
      background-color: var(--button-gray-3);
    }
  }

  .ToolIcon_type_floating {
    background-color: transparent;

    &:hover {
      background-color: transparent;
    }

    &:active {
      background-color: transparent;
    }

    .ToolIcon__icon {
      background-color: var(--button-gray-1);
      &:hover {
        background-color: var(--button-gray-2);
      }
      &:active {
        background-color: var(--button-gray-3);
      }

      width: 2rem;
      height: 2em;
    }
  }

  .ToolIcon__keybinding {
    position: absolute;
    bottom: 2px;
    right: 3px;
    font-size: 0.5em;
    color: var(--keybinding-color);
    font-family: var(--ui-font);
    user-select: none;
  }

  // shrink shape icons on small viewports to make them fit
  @media (max-width: 425px) {
    .Shape .ToolIcon__icon {
      width: 2rem;
      height: 2rem;

      svg {
        height: 0.8em;
      }
    }
  }

  // move the lock button out of the way on small viewports
  // it begins to collide with the GitHub icon before we switch to mobile mode
  @media (max-width: 760px) {
    .ToolIcon.ToolIcon_type_floating {
      display: inline-block;
      position: absolute;
      right: -8px;

      margin-left: 0;
      border-radius: 20px 0 0 20px;
      z-index: 1;

      background-color: var(--button-gray-1);

      &:hover {
        background-color: var(--button-gray-1);
      }

      &:active {
        background-color: var(--button-gray-2);
      }

      .ToolIcon__icon {
        border-radius: inherit;
      }

      svg {
        position: static;
      }
    }
    .ToolIcon.ToolIcon__library {
      top: 100px;
    }

    .ToolIcon.ToolIcon__lock {
      margin-inline-end: 0;
      top: 60px;
    }
  }

  .unlocked-icon {
    :root[dir="ltr"] & {
      left: 2px;
    }

    :root[dir="rtl"] & {
      right: 2px;
    }
  }
}
